import {
  getContacts,
  addContactsToList,
  editContactInList,
  deleteContactFromList,
  uploadContactsToList,
  getContactList,
  getContactListWithCount,
  createContactList,
  deleteContactList,
  archiveContactList,
  activateContactList,
  getArchivedContactList,
  getContactGroups,
  createContactGroup,
  addListToContactGroup,
  archiveContactGroup,
  getArchivedContactGroup,
} from "@/api/contact";
import store from "@/store";

export const contacts = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
  
    getContacts(payload)
      .then((response) => {
        if (response) {
          store.commit("contact/contacts", response);
        }
        resolve(response);
      })
      .catch((error) => {});
  });
};

export const addContact = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    addContactsToList(payload)
      .then((response) => {
        if (response && response.recordid) {
          // store.commit("contact/contacts", response)
          resolve(response);
        }
      })
      .catch((error) => {
        // reject(alert('Something went wrong, please try again'))
      });
  });
};

export const editContact = ({ commit }, payload) => {
  debugger;
  return new Promise((resolve, reject) => {
    editContactInList(payload)
      .then((response) => {
        if (response && response.recordid) {
          resolve(response);
        }
      })
      .catch((error) => {
        // reject(alert('Something went wrong, please try again'))
      });
  });
};

export const deleteContact = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    deleteContactFromList(payload)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {});
  });
};

export const uploadContacts = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    uploadContactsToList(payload)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        // reject(alert('Something went wrong, please try again'))
      });
  });
};

export const contactListWithCount = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    getContactListWithCount(payload)
      .then((response) => {
        if (response) {
          store.commit("contact/contactList", response);
        }
        resolve(response);
      })
      .catch((error) => {
        // reject(alert('Something went wrong, please try again'))
      });
  });
};

export const contactList = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    getContactList(payload)
      .then((response) => {
        if (response) {
          store.commit("contact/contactList", response);
        }
        resolve(response);
      })
      .catch((error) => {
        // reject(alert('Something went wrong, please try again'))
      });
  });
};

export const createList = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    createContactList(payload)
      .then((response) => {
        if (response) {
          let payload = {
            creationdate: "2022-09-05 11:20:51",
            id: response.listId,
            groupname: response.listname,
            totalcontacts: 0,
          };
          store.commit("contact/updateContactList", payload);
        }
        resolve(response);
      })
      .catch((error) => {
        reject();
      });
  });
};

export const deleteList = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    deleteContactList(payload)
      .then((response) => {
        if (response) {
          contactListWithCount(payload);
        }
        resolve(response);
      })
      .catch((error) => {
        reject();
      });
  });
};

export const archiveList = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    archiveContactList(payload)
      .then((response) => {
        if (response) {
          contactListWithCount(payload);
        }
        resolve(response);
      })
      .catch((error) => {
        reject();
      });
  });
};

export const activateList = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    activateContactList(payload)
      .then((response) => {
        if (response) {
          getArchivedList(payload);
        }
        resolve(response);
      })
      .catch((error) => {
        reject();
      });
  });
};

export const getArchivedList = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    getArchivedContactList(payload)
      .then((response) => {
        store.commit("contact/archivedList", response);
        resolve(response);
      })
      .catch((error) => {
        reject();
      });
  });
};

/**
 *  Contact Groups Starts Here
 */

export const contactGroups = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    getContactGroups(payload)
      .then((response) => {
        store.commit("contact/contactGroup", response);
        resolve(response);
      })
      .catch((error) => {
        // reject(alert('Something went wrong, please try again'))
      });
  });
};

export const createGroup = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    createContactGroup(payload)
      .then((response) => {
        if (response) {
          let payload = {
            id: response.groupid,
            groupname: response.groupname,
            count: 0,
          };
          store.commit("contact/updateContactGroup", payload);
        }
        resolve(response);
      })
      .catch((error) => {
        reject();
      });
  });
};

export const addListToGroup = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    addListToContactGroup(payload)
      .then((response) => {
        if (response) {
          contactGroups(payload);
        }
        resolve(response);
      })
      .catch((error) => {
        reject();
      });
  });
};

export const archiveGroup = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    archiveContactGroup(payload)
      .then((response) => {
        store.commit("contact/archivedGroup", response);
        resolve(response);
      })
      .catch((error) => {
        reject();
      });
  });
};

export const getArchiveGroup = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    getArchivedContactGroup(payload)
      .then((response) => {
        store.commit("contact/archivedGroup", response);
        resolve(response);
      })
      .catch((error) => {
        reject();
      });
  });
};

// export const addaListToGroup = ({ commit }, payload) => {
//   return new Promise((resolve, reject) => {
//     addListToContactGroup(payload)
//       .then((response) => {
//         if (response) {
//           contactGroups(payload);
//         }
//         resolve(response);
//       })
//       .catch((error) => {
//         reject();
//       });
//   });
// };
